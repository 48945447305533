// createRouter 创建路由实例，===> new VueRouter()
// history 是路由模式，hash模式，history模式
// createWebHistory() 是开启history模块   http://xxx/user
// createWebHashHistory() 是开启hash模式    http://xxx/#/user

// vite 的配置 import.meta.env.BASE_URL 是路由的基准地址，默认是 ’/‘
// https://vitejs.dev/guide/build.html#public-base-path
// 如果将来你部署的域名路径是：http://xxx/my-path/user
// vite.config.ts  添加配置  base: my-path，路由这就会加上 my-path 前缀了
import {createRouter, createWebHistory} from 'vue-router'
import NProgress from 'nprogress' // 引入进度条
import 'nprogress/nprogress.css' // 引入进度条样式
import {useUserStore} from "../stores";
import wechat from '@/utils/wechat'
const shareWihteList = ['服务详情']
NProgress.configure({
    showSpinner: false
})
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // 登录
        {
            path: '/login',
            component: () => import('@/views/Login/index.vue'),
            meta: {title: '登录', isLogin: false}
        },
        // 首页
        {
            path: '/',
            component: () => import('@/views/Layout/index.vue'),
            redirect: '/home',
            children: [
                {path: '/home', component: () => import('@/views/Home/index.vue'), meta: {title: '首页', isLogin: false}},
                {path: '/user', component: () => import('@/views/User/index.vue'), meta: {title: '我的', isLogin: true}}
            ]
        },
        // 电表编号维护
        {
            path: '/user/meters',
            component: () => import('@/views/User/Meters.vue'),
            meta: {title: '电表编号维护', isLogin: true}
        },
        // 手机号码维护
        {
            path: '/user/mobiles',
            component: () => import('@/views/User/Mobiles.vue'),
            meta: {title: '手机号码维护', isLogin: true}
        },
        // 收货地址维护
        {
            path: '/user/address',
            component: () => import('@/views/User/Address.vue'),
            meta: {title: '收货地址维护', isLogin: true}
        },
        // 服务详情
        {
            path:'/service/details/:id/:share?',
            component:()=> import('@/views/ServiceDetails/index.vue'),
            meta:{title: '服务详情',isLogin: false}
        },
        // 商品订单
        {
            path:'/goods/order',
            name:'goodsOrder',
            component:()=> import('@/views/GoodsOrder/index.vue'),
            meta:{title: '商品订单',isLogin: true}
        },
        // 我的订单
        {
            path:'/user/order/:orderType',
            component:()=> import('@/views/User/order.vue'),
            meta:{title: '我的订单',isLogin: true}
        },
        // 我的订单
        {
            path:'/user/pddorder/',
            component:()=> import('@/views/User/pddOrder.vue'),
            meta:{title: '拼多多订单',isLogin: true}
        },
        // 商品列表
        {
            path:'/goods/sort/list/:goodsClass',
            component:()=> import('@/views/GoodsSortList/index.vue'),
            meta:{title: '商品列表',isLogin: true}
        },
        // 支付成功
        {
            path:'/payend',
            component:()=> import('@/views/Payend/index.vue'),
            meta:{title: '支付成功',isLogin: true}
        },
        // 官方客服
        {
            path: '/contact',
            component: () => import('@/views/contact/index.vue'),
            meta: { isLogin: true, title: '官方客服' }
        },
        // 分享海报
        {
            path: '/poster',
            component: () => import('@/views/Poster/index.vue'),
            meta: { isLogin: true, title: '分享海报' }
        },
        // 我的粉丝
        {
            path: '/fans',
            component: () => import('@/views/User/fans.vue'),
            meta: { isLogin: true, title: '我的粉丝' }
        },
        // 粉丝订单
        {
            path: '/fans/order',
            component: () => import('@/views/User/fans-order.vue'),
            meta: { isLogin: true, title: '粉丝订单' }
        },
    // 手机充值
        {
            path: '/phone/bill',
            component: () => import('@/views/PhoneBill/index.vue'),
            meta: { isLogin: true, title: '手机充值' }
        },
        // 电费充值
        {
            path: '/electricity/bill',
            component: () => import('@/views/ElectricityBill/index.vue'),
            meta: { isLogin: true, title: '电费代缴' }
        },
    //    燃气充值
        {
            path: '/gas/bill',
            component: () => import('@/views/gasBill/index.vue'),
            meta: { isLogin: true, title: '燃气充值' }
        },
        //    燃气充值
        {
            path: '/search',
            component: () => import('@/views/search/index.vue'),
            meta: { isLogin: false, title: '搜索' }
        },
        {
            path: '/ETC/apply',
            component: () => import('@/views/ETC/index.vue'),
            meta: { isLogin: false, title: 'ETC申请' }
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        return { top: 0 }
    }
})
// 访问权限控制
router.beforeEach((to, from) => {
    NProgress.start() // 显示进度条
    const store = useUserStore()

    if (to.meta.isLogin && !store.token) {
        return {path: '/login', query:{redirect:to.fullPath} }
    }
})

router.afterEach((to) => {
    const store = useUserStore()
    document.title = `香落生活-${to.meta.title || ''}`
    NProgress.done() // 关闭进度条
    if ( !shareWihteList.includes(to.meta.title)) {
        wechat.share({
            title: '全国话费，电话优惠充值',
            desc: '全国充值一律95折起，优惠多多，实惠多多，持续关注香落生活缴费！',
            link: store.user.auth === 1 ? 'https://center.zxlx.top?share=' +
                store.token : 'https://center.zxlx.top',
            image: 'http://wxpage.goto365.top/goto_logo.png'
        }, (res) => {
            console.log(res)
        })
    }
})

export default router
